import ApiService from "@/services/api.service";
import logger from "@/logger";
import Caver from "caver-js";
import {GET_FEATURED_PRODUCT, SET_FEATURED_PRODUCT} from "./threespace.gallery";
import Web3 from "web3";

// action types
export const VERIFY_WALLET = "verifyWallet";
export const GET_BALANCE = "getBalance";
export const GET_BTC_BALANCE = "getBtcBalance";
export const GET_ETHEREUM_BALANCE = "getEthereumBalance";
export const GET_KLAYTN_BALANCE = "getKlaytnBalance";
export const GET_KLAYTN_TOKEN_BALANCE = "getKlaytnTokenBalance";
export const WALLET_LOGIN = "walletLogin";
export const WEPIN_LOGIN = "wepinLogin";
export const CHANGE_WALLET = "changeWallet";
export const GET_TRANSACTIONS = "getTransactions";
export const GET_REWARD_TOTAL = "getRewardTotal";
export const GET_KPACE_REWARD = "getKpaceReward";
export const COMPLETE_CLAIM = "completeClaim";
export const CLAIM_PACE = "claimPace";
export const GET_PACE_BALANCE = "getPaceBalance";
export const GET_ETHEREUM_TRANSACTION_RESULT = "getEthereumTransactionResult";
export const GET_TRANSACTION_RESULT = "getTransactionResult";
export const GET_NONCE = "getNonce";
export const GET_BRIDGE_INFO = "getBridgeInfo";
export const IS_AIRDROP_USER = "isAirdropUser";
export const GET_INSCRIPTION_INFO = "getInscriptionInfo";

// mutation types
export const SET_WALLET = "setWallet";
export const SET_BTC_BALANCE = "setBtcBalance";
export const SET_ETHEREUM_BALANCE = "setEthereumBalance";
export const SET_ETHEREUM_TOKEN_BALANCE = "setEthereumTokenBalance";
export const SET_KLAYTN_BALANCE = "setKlaytnBalance";
export const SET_KLAYTN_TOKEN_BALANCE = "setKlaytnTokenBalance";
export const SET_TRANSACTIONS = "setTransactions";
export const SET_REWARD_TOTAL = "setRewardTotal";
export const RESET_TRANSACTIONS = "resetTransactions";
export const SET_KPACE_REWARD = "setKpaceReward";
export const SET_BRIDGE_INFO = "setBridgeInfo";
export const SET_WALLET_PROVIDER = "setWalletProvider";

const state = {
  provider: null,
  // addr: "0x",
  // quantity: 0,
  // symbol: "",
  // simpleAddr: "0x",
  // simpleQuantity: 0,
  // chainId: null,
  // wallet: null,
  balance: {
    btc: {
      symbol: "BTC",
      addr: null,
      simpleAddr: null,
      quantity: null,
      simpleQuantity: null,
      chainId: null,
    },
    ethereum: {
      symbol: "ETH",
      addr: null,
      simpleAddr: null,
      quantity: null,
      simpleQuantity: null,
      chainId: null,
    },
    klaytn: {
      symbol: "KAIA",
      addr: null,
      simpleAddr: null,
      quantity: null,
      simpleQuantity: null,
      chainId: null,
    },
    pace:{
      symbol: "PACE",
      addr: null,
      simpleAddr: null,
      quantity: 0,
      simpleQuantity: 0,
      decimals: 18,
      chainId: null,
    },
    kpace: {
      symbol: "oPACE",
      addr: null,
      simpleAddr: null,
      quantity: 0,
      simpleQuantity: 0,
      decimals: 18,
      chainId: null,
    },
    ppace: {
      symbol: "pPACE",
      addr: null,
      simpleAddr: null,
      quantity: 0,
      simpleQuantity: 0,
      decimals: 18,
      chainId: null,
    },
  },
  claim: {
    pace: null,
    opace: null,
    ppace: null
  },
  bridgeInfo: {
    ethSwapFee: null,
    klaySwapFee: null,
  },
  transactionList: {},
  transactionListContent: [],
  transactionTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
  ]
};

const getters = {};

const actions = {
  async [GET_BALANCE](context) {
    const me = this.state.auth.me;

    if(me.ethereumAddr){
      const web3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))

      web3.eth.getBalance(me.ethereumAddr, function(err, result){
        if(err) console.log(err)
        context.commit(SET_ETHEREUM_BALANCE, {
          balance: result,
          decimals: 18,
          addr: me.ethereumAddr
        })
      })
    }

    if(me.klaytnAddr){
      const accessKeyId = process.env.VUE_APP_KAS_ACCESS_KEY_ID;
      const secretAccessKey = process.env.VUE_APP_KAS_SECRET_ACCESS_KEY;
      const chainId = process.env.VUE_APP_KLAYTN_CHAIN_ID;

      const option = {
        headers: [
          {
            name: "Authorization",
            value: "Basic " + Buffer.from(accessKeyId + ':' + secretAccessKey).toString('base64')
          },
          {name: "x-chain-id", value: chainId}
        ]
      }

      const caver = new Caver(new Caver.providers.HttpProvider("https://node-api.klaytnapi.com/v1/klaytn", option));

      caver.rpc.klay.getBalance(me.klaytnAddr)
        .then(( data ) => {
          const balance = caver.utils.convertFromPeb(caver.utils.hexToNumberString(data));
          const result = {
            addr:me.klaytnAddr,
            balance: balance,
            decimals: 0,
            symbol: "KAIA",
          }
          context.commit(SET_KLAYTN_BALANCE, result);
        })
        .catch(error => {
          logger.error("[klay] error: ", error, error.response);
        });

      //TODO pace, kpace 토큰수량 받아와야함.
    }

    if(me.btcAddr){
      const apiUrl = `https://blockchain.info/rawaddr/${me.btcAddr}`;

      try {
        const response = await fetch(apiUrl);

        if (response.ok) {
          const data = await response.json();
          const finalBalance = data.final_balance; // Convert from satoshis to BTC

          const result = {
            addr:me.btcAddr,
            balance: finalBalance,
            decimals: 8,
            symbol: "BTC"
          }
          context.commit(SET_BTC_BALANCE, result);
        } else {
          return "Error: Unable to fetch balance.";
        }
      } catch (error) {
        return "Error: Unable to fetch balance.";
      }
    }
  },
  async [GET_BTC_BALANCE](context, addr) {
    const me = this.state.auth.me;

    if(!addr && me.btcAddr){
      addr = me.btcAddr;
    }

    if(addr){
      const apiUrl = `https://blockchain.info/rawaddr/${addr}`;

      try {
        const response = await fetch(apiUrl);

        if (response.ok) {
          const data = await response.json();
          const finalBalance = data.final_balance; // Convert from satoshis to BTC

          const result = {
            addr,
            balance: finalBalance,
            decimals: 8,
            symbol: "BTC"
          }
          context.commit(SET_BTC_BALANCE, result);
        } else {
          return "Error: Unable to fetch balance.";
        }
      } catch (error) {
        return "Error: Unable to fetch balance.";
      }
    }
  },
  [GET_ETHEREUM_BALANCE](context, addr) {
    const me = this.state.auth.me;

    if(!addr && me.ethereumAddr){
      addr = me.ethereumAddr;
    }

    if(addr){
      const web3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))

      web3.eth.getBalance(me.ethereumAddr, function(err, result){
        if(err) console.log(err)
        context.commit(SET_ETHEREUM_BALANCE, {
          addr,
          balance: result,
          decimals: 18,
        })
      })
    }
  },
  [GET_KLAYTN_BALANCE](context, addr) {
    const me = this.state.auth.me;

    if(!addr && me.klaytnAddr){
      addr = me.klaytnAddr;
    }

    if(addr) {
      const accessKeyId = process.env.VUE_APP_KAS_ACCESS_KEY_ID;
      const secretAccessKey = process.env.VUE_APP_KAS_SECRET_ACCESS_KEY;
      const chainId = process.env.VUE_APP_KLAYTN_CHAIN_ID;

      const option = {
        headers: [
          {
            name: "Authorization",
            value: "Basic " + Buffer.from(accessKeyId + ':' + secretAccessKey).toString('base64')
          },
          {name: "x-chain-id", value: chainId}
        ]
      }

      const caver = new Caver(new Caver.providers.HttpProvider("https://node-api.klaytnapi.com/v1/klaytn", option));

      caver.rpc.klay.getBalance(addr)
        .then((data) => {
          const balance = caver.utils.convertFromPeb(caver.utils.hexToNumberString(data));
          const result = {
            addr,
            balance: balance,
            decimals: 0,
            symbol: "KAIA",
          }
          context.commit(SET_KLAYTN_BALANCE, result);
        })
        .catch(error => {
          logger.error("[klay] error: ", error, error.response);
        });
    }
  },
  async [GET_KLAYTN_TOKEN_BALANCE](context, tokenAddress, addr) {
    const me = this.state.auth.me;

    if(!addr && me.klaytnAddr){
      addr = me.klaytnAddr;
    }

    if(addr){
      const accessKeyId = process.env.VUE_APP_KAS_ACCESS_KEY_ID;
      const secretAccessKey = process.env.VUE_APP_KAS_SECRET_ACCESS_KEY;
      const chainId = process.env.VUE_APP_KLAYTN_CHAIN_ID;

      const option = {
        headers: [
          {
            name: "Authorization",
            value: "Basic " + Buffer.from(accessKeyId + ':' + secretAccessKey).toString('base64')
          },
          {name: "x-chain-id", value: chainId}
        ]
      }

      const caver = new Caver(new Caver.providers.HttpProvider(process.env.VUE_APP_CAVER_RPC_URL, option))
      const token = caver.kct.kip7.create(tokenAddress);
      const symbol = "kpace";
      const decimals = await token.decimals();
      const amount = await token.balanceOf(addr);

      const data = {
        amount,
        symbol,
        decimals
      };

      context.commit(SET_KLAYTN_TOKEN_BALANCE, data);
    }
  },
  [VERIFY_WALLET](context, payload) {

  },
  [WEPIN_LOGIN](context, payload) {
    let referral = localStorage.getItem("referral3spaceJoin");
    if (referral === undefined || referral === null || referral === "") {
      referral = "";
    }

    const url = process.env.VUE_APP_BACKEND_API_URL +
    `/threespace/v1/login/wepin/callback?email=${payload.email}&userId=${payload.userId}&provider=${payload.provider}&referral=${referral}`;

    window.location.href = url;
  },
  [WALLET_LOGIN](context, payload) {
    let canLogin = true;

    if(process.env.NODE_ENV === "production"){
      //if(payload.network === "Ethereum" && payload.chainId.toString() !== "1"){
      //  canLogin = false;
      //  alert("Please select the Ethereum Mainnet");
     // }
      // else if(payload.network === "Klaytn" && payload.chainId.toString() !== "8217"){
      //   canLogin = false;
      //   alert("Please select the Klaytn Mainnet");
      // }
    }

    if(process.env.NODE_ENV !== "production"){
      if(payload.network === "Ethereum" && payload.chainId.toString() !== process.env.VUE_APP_ETHEREUM_CHAIN_ID){
        // alert("We highly recommend using Goerli Network in Test server");
      }
      // else if(payload.network === "Klaytn" && payload.chainId.toString() !== "1001"){
      //   alert("We highly recommend using baobab network in Test server");
      // }
    }

    if(canLogin){
      let referral = localStorage.getItem("referral3spaceJoin");
      if (referral === undefined || referral === null || referral === "") {
        referral = "";
      }

      const walletInfo = {
        network:payload.network,
        chainId:payload.chainId,
        address:payload.addr,
        walletProvider:payload.walletProvider,
        addrType:payload.addrType,
        ...payload
      }
      localStorage.setItem("walletInfo",JSON.stringify(walletInfo));

      const url = process.env.VUE_APP_BACKEND_API_URL +
        `/threespace/v1/login/wallet/callback?addr=${payload.addr}&chainId=${payload.chainId}&network=${payload.network}&referral=${referral}&walletProvider=${payload.walletProvider}&addrType=${payload.addrType}&ordinalsAddr=${payload.ordinalsAddr}`;

      // context.commit(SET_WALLET, payload);
      window.location.href = url;
    }
  },
  // [CHANGE_WALLET](context) {
  //
  // },
  [GET_TRANSACTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/transaction/v1/reward/dashboard", payload)
        .then(({ data }) => {
          context.commit(SET_TRANSACTIONS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_REWARD_TOTAL](context, payload) {
    //TODO 블록체인 데이터 호출 해야함.
    return new Promise((resolve, reject) => {
      ApiService.get("/transaction/v1/reward/total", payload)
        .then(({ data }) => {
          context.commit(SET_REWARD_TOTAL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_KPACE_REWARD](context, payload) {
    return new Promise((resolve, reject) => {
      const me = this.state.auth.me;
      const addr = payload || me.klaytnAddr

      if(addr) {
        const accessKeyId = process.env.VUE_APP_KAS_ACCESS_KEY_ID;
        const secretAccessKey = process.env.VUE_APP_KAS_SECRET_ACCESS_KEY;
        const chainId = process.env.VUE_APP_KLAYTN_CHAIN_ID;

        const option = {
          headers: [
            {
              name: "Authorization",
              value: "Basic " + Buffer.from(accessKeyId + ':' + secretAccessKey).toString('base64')
            },
            {name: "x-chain-id", value: chainId}
          ]
        }

        const caver = new Caver(new Caver.providers.HttpProvider(process.env.VUE_APP_CAVER_RPC_URL, option))

        const contract = caver.contract.create(
          [{
            "constant": true,
            "inputs": [
              {
                "name": "_owner",
                "type": "address"
              },
              {
                "name": "_spender",
                "type": "address"
              }
            ],
            "name": "allowance",
            "outputs": [
              {
                "name": "",
                "type": "uint256"
              }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
          }],
          process.env.VUE_APP_KPACE_ADDRESS,
          {from: addr}
        )

        contract.call("allowance", process.env.VUE_APP_CLAIM_CONTRACT_ADDRESS, addr)
          .then((data) => {
            const amount = data / 10 ** 18
            context.commit(SET_KPACE_REWARD, amount);
            resolve(amount);
          })
          .catch(({response}) => {
            logger.error(response);
            reject(response);
          });
      }
    })
  },
  [COMPLETE_CLAIM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/transaction/v1/reward/claim", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [CLAIM_PACE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/transaction/v1/reward/claim/pace", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_PACE_BALANCE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/balance/pace", payload)
        .then(({ data }) => {
          context.commit(SET_ETHEREUM_TOKEN_BALANCE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ETHEREUM_TRANSACTION_RESULT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/transaction/v1/reward/claim/result?txHash=${payload}`)
        .then(({ data }) => {
          resolve(data.data);
      })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        })
    })
  },
  [GET_TRANSACTION_RESULT](context, payload) {
    const accessKeyId = process.env.VUE_APP_KAS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.VUE_APP_KAS_SECRET_ACCESS_KEY;
    const chainId = process.env.VUE_APP_KLAYTN_CHAIN_ID;

    const option = {
      headers: [
        {
          name: "Authorization",
          value: "Basic " + Buffer.from(accessKeyId + ':' + secretAccessKey).toString('base64')
        },
        {name: "x-chain-id", value: chainId}
      ]
    }

    const caver = new Caver(new Caver.providers.HttpProvider(process.env.VUE_APP_CAVER_RPC_URL, option))

    return caver.klay.getTransactionReceipt(payload);
  },
  [GET_NONCE](context, payload) {
    const accessKeyId = process.env.VUE_APP_KAS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.VUE_APP_KAS_SECRET_ACCESS_KEY;
    const chainId = process.env.VUE_APP_KLAYTN_CHAIN_ID;

    const option = {
      headers: [
        {
          name: "Authorization",
          value: "Basic " + Buffer.from(accessKeyId + ':' + secretAccessKey).toString('base64')
        },
        {name: "x-chain-id", value: chainId}
      ]
    }

    const caver = new Caver(new Caver.providers.HttpProvider(process.env.VUE_APP_CAVER_RPC_URL, option))

    return caver.klay.getTransactionCount(payload);
  },
  [GET_BRIDGE_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/bridge/prepare", payload)
        .then(({ data }) => {
          context.commit(SET_BRIDGE_INFO, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [IS_AIRDROP_USER]() {
    return new Promise((resolve, reject) => {
      ApiService.get("/transaction/v1/neopin-airdrop/user")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_INSCRIPTION_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      fetch(`https://api.hiro.so/ordinals/v1/inscriptions/${payload}`)
        .then((response) => {
          resolve(response.json());
        })
        .catch((error) => {
          reject(error)
        });
    })
  }
}

const mutations = {
  [SET_BTC_BALANCE](state, payload) {
    const balance = payload.balance;
    const decimals = payload.decimals;
    const quantity = (balance / 10 ** decimals).toString();
    const addr = payload.addr;

    state.balance.btc.quantity = quantity;
    state.balance.btc.addr = addr;
    state.balance.btc.simpleQuantity = quantity.length > 5 ? quantity.slice(0,5) + ".." : quantity;
    state.balance.btc.simpleAddr = addr.slice(0,6) + "..." + addr.slice(-8);
  },
  [SET_ETHEREUM_BALANCE](state, payload) {
    const balance = payload.balance;
    const decimals = payload.decimals;
    const quantity = (balance / 10 ** decimals).toString();
    const addr = payload.addr;

    state.balance.ethereum.quantity = quantity;
    state.balance.ethereum.addr = addr;
    state.balance.ethereum.simpleQuantity = quantity.length > 5 ? quantity.slice(0,5) + ".." : quantity;
    state.balance.ethereum.simpleAddr = addr.slice(0,6) + "..." + addr.slice(-8);
  },
  [SET_ETHEREUM_TOKEN_BALANCE](state, payload){
    // TODO 다른 토큰 적용 필요.

    if(payload){
      const quantity = (payload / 10 ** 18).toString();
      state.balance.pace.quantity = quantity;
    }
  },
  [SET_KLAYTN_BALANCE](state, payload) {
    const balance = payload.balance;
    const decimals = payload.decimals;
    const quantity = (balance / 10 ** decimals).toString();
    const addr = payload.addr;

    state.balance.klaytn.quantity = quantity;
    state.balance.klaytn.addr = addr;
    state.balance.klaytn.simpleQuantity = quantity.length > 5 ? quantity.slice(0,5) + ".." : quantity;
    state.balance.klaytn.simpleAddr = addr.slice(0,6) + "..." + addr.slice(-8);
  },
  [SET_KLAYTN_TOKEN_BALANCE](state, payload) {
    const token = payload.symbol.toLowerCase();
    const amount = payload.amount;
    const decimals = payload.decimals;
    const quantity = Caver.utils.fromPeb(amount, 'KLAY');

    state.balance[token] = {};
    state.balance[token].quantity = quantity;
    state.balance[token].symbol = "oPACE";
  },
  // [SET_WALLET](state, payload) {
  //   state.addr = payload.addr;
  //   state.chainId = payload.chainId;
  //   state.wallet = payload.wallet;
  // },
  [SET_TRANSACTIONS](state, transactionList) {
    state.transactionList = transactionList;

    let content = [...transactionList.content]
    state.transactionListContent = content;
  },
  [SET_REWARD_TOTAL](state, reward) {
    state.claim.pace = reward.paceTotal;
    state.claim.ppace = reward.polygonPaceTotal;

    // kpace는 블록상의 데이터로 바로 호출
    // state.claim.kpace = reward.kpaceTotal;
  },
  [RESET_TRANSACTIONS](state) {
    state.transactionListContent = [];
  },
  [SET_KPACE_REWARD](state, amount) {
    state.claim.opace = amount;
  },
  [SET_BRIDGE_INFO](state, bridgeInfo) {
    state.bridgeInfo = bridgeInfo;
  },
  [SET_WALLET_PROVIDER](state, provider) {
    state.provider = provider;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
