import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
// 가상계좌 결제
export const GET_PAYMENT_VBANK = "getPaymentVbank";
export const ADD_PAYMENT_VBANK = "addPaymentVbank";

// TOSS 가상계좌
export const GET_TOSS_PAYMENT_VBANK = "getTossPaymentVbank";
export const CONFIRM_TOSS_PAYMENT_VBANK = "confirmTossPaymentVbank";

// TOSS 실시간 계좌이체
export const GET_TOSS_PAYMENT_TRANSFER = "getTossPaymentTransfer";
export const CONFIRM_TOSS_PAYMENT_TRANSFER = "confirmTossPaymentTransfer";

// 클립결제
export const GET_PAYMENT_KLAY = "getPaymentKlay";
export const UPDATE_PAYMENT_KLAY = "updatePaymentKlay";
export const COMPLETE_PAYMENT_KLAY = "completePaymentKlay";
export const COMPLETE_PAYMENT_KLAY_PAYCONTRACT = "completePaymentKlayPaycontract";
export const PREPARE_TOKEN_PAYMENT = "prepareTokenPayment"; // 클레이기반 계열 결제 전 준비

// 이더결제
export const GET_PAYMENT_ETH = "getPaymentEth";
export const UPDATE_PAYMENT_ETH = "updatePaymentEth";
export const COMPLETE_PAYMENT_ETH_PAYCONTRACT = "completePaymentEthPaycontract";
export const PREPARE_ETH_PAYMENT = "prepareEthPayment"; // 이더리움기반 계열 결제 전 준비

// 매틱결제
export const GET_PAYMENT_MATIC = "getPaymentMatic";
export const UPDATE_PAYMENT_MATIC = "updatePaymentMatic";
export const COMPLETE_PAYMENT_MATIC_PAYCONTRACT = "completePaymentMaticPaycontract";
export const PREPARE_MATIC_PAYMENT = "prepareMaticPayment"; // 폴리곤기반 계열 결제 전 준비

// 비트코인결제
export const GET_PAYMENT_BTC = "getPaymentBtc";
export const UPDATE_PAYMENT_BTC = "updatePaymentBtc";
// export const COMPLETE_PAYMENT_MATIC_PAYCONTRACT = "completePaymentMaticPaycontract";

// 간편결제
export const GET_PAYMENT_CARD = "getPaymentCard";
export const UPDATE_PAYMENT_CARD_PC = "updatePaymentCardPC"; // 모바일은 Server-side callback 형태로 구현되어 있음
export const TRANSFER_NFT = "transferNFT";

// 계좌이체
export const GET_PAYMENT_WIRE_TRANSFER = "getPaymentWireTransfer";
export const ADD_PAYMENT_WIRE_TRANSFER = "addPaymentWireTransfer";

// 토큰결제 (SSX, KPACE ...)
export const GET_PAYMENT_TOKEN = "getPaymentToken";
export const COMPLETE_PAYMENT_TOKEN = "completePaymentToken";

// 마이페이지 결제내역 조회
export const GET_PAYMENT_LIST = "getPaymentList";

// mutation types
export const RESET_PAYMENT = "resetPayment";
export const SET_PAYMENT = "setPayment";
export const SET_PAYMENT_LIST = "setPaymentList";
export const SET_TRANSFER_NFT = "setTransferNFT";
export const SET_PREPARE_PAYMENT = "setPreparePayment";

const state = {
  errors: null,
  paymentInfo: {},
  paymentList: {
    content: []
  },
  transferNFTStatus: new Array(), // 간편결제(모바일) 구매완료후 전송처리된 NFT
  preparePaymentInfo: {}
};

const getters = {};

const actions = {
  /** ====================================
   * VBANK > 가상계좌
   ==================================== */
  [GET_PAYMENT_VBANK](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/vbank/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_PAYMENT_VBANK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/vbank", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_TOSS_PAYMENT_VBANK](context, payload) {
    return new Promise((resolve, reject) => {
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/toss/payments/vbank/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [CONFIRM_TOSS_PAYMENT_VBANK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/toss/payments/vbank", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  /** ====================================
   * TRANSFER > TOSS 실시간 계좌이체
   ==================================== */
  [GET_TOSS_PAYMENT_TRANSFER](context, payload) {
    return new Promise((resolve, reject) => {
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/toss/payments/transfer/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [CONFIRM_TOSS_PAYMENT_TRANSFER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/toss/payments/transfer", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  /** ====================================
   * KLAY > token (ssx, kpace)
   ==================================== */
  [GET_PAYMENT_TOKEN](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload.idxProduct);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/token/" + payload.idxProduct + "/" + referral + "/" + payload.currency)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [COMPLETE_PAYMENT_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/token", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  /** ====================================
   * KLAY
   ==================================== */
  [GET_PAYMENT_KLAY](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/klay/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PAYMENT_KLAY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/payment/v1/payments/klay/" + payload.idxOrderPayment + "/notUse", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [COMPLETE_PAYMENT_KLAY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/klay", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [COMPLETE_PAYMENT_KLAY_PAYCONTRACT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/klay/paycontract", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [PREPARE_TOKEN_PAYMENT](context, payload){
    return new Promise((resolve, reject) => {
      ApiService.get(`/payment/v1/payments/prepare/${payload.payment}/${payload.currency}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  /** ====================================
   * ETH
   ==================================== */
  [GET_PAYMENT_ETH](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/eth/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PAYMENT_ETH](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/payment/v1/payments/eth/" + payload.idxOrderPayment + "/notUse", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [COMPLETE_PAYMENT_ETH_PAYCONTRACT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/eth/paycontract", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [PREPARE_ETH_PAYMENT](context, payload){
    return new Promise((resolve, reject) => {
      ApiService.get(`/payment/v1/payments/prepare/${payload.payment}/eth`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  /** ====================================
   * MATIC
   ==================================== */
  [GET_PAYMENT_MATIC](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/matic/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PAYMENT_MATIC](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/payment/v1/payments/matic/" + payload.idxOrderPayment + "/notUse", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [COMPLETE_PAYMENT_MATIC_PAYCONTRACT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/matic/paycontract", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [PREPARE_MATIC_PAYMENT](context, payload){
    return new Promise((resolve, reject) => {
      ApiService.get(`/payment/v1/payments/prepare/${payload.payment}/matic`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  /** ====================================
   * BTC
   ==================================== */
  [GET_PAYMENT_BTC](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/btc/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PAYMENT_BTC](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/payment/v1/payments/btc/" + payload.idxOrderPayment + "/notUse", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  /** ====================================
   * CARD > 간편결제
   ==================================== */
  [GET_PAYMENT_CARD](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/card/" + payload + "/" + referral)
        .then(({ data }) => {
          context.commit(SET_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PAYMENT_CARD_PC](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/payment/v1/payments/card/" + payload.idxOrderPayment + "/notUse", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_PAYMENT_WIRE_TRANSFER](context, payload) {
    context.commit(RESET_PAYMENT); // 초기화
    return new Promise((resolve, reject) => {
      // 법인 식별 정보
      let referral = localStorage.getItem("referralCorpSeller_" + payload);
      if (referral === undefined || referral === null || referral === "") {
        referral = "0";
      }
      ApiService.get("/payment/v1/payments/wiretransfer/" + payload + "/" + referral)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },

  [ADD_PAYMENT_WIRE_TRANSFER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/wiretransfer", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },

  /** ====================================
   * TRANSFER_NFT
   ==================================== */
  [TRANSFER_NFT](context, payload) {
    // 전송처리된 결제정보 저장(재전송 방지용)
    context.commit(SET_TRANSFER_NFT, payload.idxOrderPayment);

    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/nft-transfer", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_PAYMENT_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/payment/v1/payment-list", payload)
        .then(({ data }) => {
          context.commit(SET_PAYMENT_LIST, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [RESET_PAYMENT](state) {
    state.paymentInfo = {};
  },
  [SET_PAYMENT](state, paymentInfo) {
    state.paymentInfo = paymentInfo;
  },
  [SET_PAYMENT_LIST](state, paymentList) {
    state.paymentList = paymentList;
  },
  [SET_TRANSFER_NFT](state, idxOrderPayment) {
    if (state.transferNFTStatus === null) {
      state.transferNFTStatus = new Array();
    }
    if (!state.transferNFTStatus.includes(idxOrderPayment)) {
      state.transferNFTStatus.push(idxOrderPayment);
    }
  },
  [SET_PREPARE_PAYMENT](state, paymentInfo) {
    state.preparePaymentInfo = paymentInfo
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
